const API_URL = "https://zxy-api.work";
const DEV_API_URL = "http://localhost:5000";

const ARBITRUM_CHAIN_ID = "0xa4b1";
const SEPOLIA_CHAIN_ID = "0xaa36a7";

const SM_BREAKPOINT = "520px";
const MD_BREAKPOINT = "900px";

const CONTRACT_ADDRESS = "0x2cdB6262463045E60b158CE80b4AD488d455D74c";
const SEPOLIA_CONTRACT_ADDRESS = "0xa598969680600a54baa09b5c706ab6fc9e9cc078";

const BTC_ADDRESS = "bc1qhg6ckwjshkqq9peje676x58lpa5sjs0trs7rzq";
const EVM_ADDRESS = "0xeFBf173Ea4BFfC61217Aa1f34Eb4D2F0c594A54a";
const XMR_ADDRESS =
  "48LSDiPohz1MbWNb7gXKjjZ84HtC6CH6ngh3Ln3F4F3xa3fnYLXZUt2bEzwJTgH9V35Ami4JTvzsGED6NZTVKMDg3kaQEs3";

const ADDRESSES = [
  ["BTC", BTC_ADDRESS],
  ["EVM", EVM_ADDRESS],
  ["XMR", XMR_ADDRESS],
];

const ERRORS = {
  switchChain: "This app is only available on Arbitrum. Please switch chains.",
};

const MAX_BOOK_TEXT_INDEX = 31;

const PDF_FILE_NAME = "right-to-transact.pdf";

const BLACK = "rgb(23, 29, 39)";
const BLUE = "skyblue";
const FADED_BLACK = "rgba(23, 29, 39, 0.95)";
const GRAY = "rgba(255, 255, 255, 0.5)";
const WHITE = "rgba(255, 255, 255, 0.95)";

const TLDR_SECTIONS = [
  "Until a few decades ago, we enjoyed a near-complete freedom to transact when, where and with whom we pleased. Centralized control over the ability to buy and sell was impossible, as was centralized surveillance of individual transactions.",
  "This freedom was a good thing, and now, as a result of rapidly evolving technology, it's disappearing.",
  "The Canadian government has invoked emergency powers to freeze protesters' bank accounts without due process. PayPal has declared its intention to monitor users' speech and fine them for expressing views the company considers objectionable.",
  "Across the industrialized world, central banks are planning to replace national currencies with central bank digital currencies (CBDCs). These will enable states to track and, if they wish, block or reverse any monetary transaction, no matter how trivial.",
  "We can't accept this outcome. The freedom to transact needs to be seen as a fundamental right, on par with the freedom of speech. To do otherwise risks forfeiting protections we once took for granted against potentially horrific abuse: the ability to earn money and spend it on daily necessities without seeking the approval of powerful, unaccountable institutions.",
];

// TODO: ts is complaining but it seems to work
const MINT_PRICE = 8_000_000_000_000_000;

const AMAZON_BOOK_URL =
  "https://www.amazon.com/right-transact-Zelinar-XY/dp/B0CDYKTGC5/";

const ARBISCAN_URL = "https://arbiscan.io";
const SEPOLIA_ETHERSCAN_URL = "https://sepolia.etherscan.io";

export {
  ADDRESSES,
  AMAZON_BOOK_URL,
  API_URL,
  ARBISCAN_URL,
  ARBITRUM_CHAIN_ID,
  BLACK,
  BLUE,
  CONTRACT_ADDRESS,
  DEV_API_URL,
  ERRORS,
  FADED_BLACK,
  GRAY,
  MAX_BOOK_TEXT_INDEX,
  MD_BREAKPOINT,
  MINT_PRICE,
  PDF_FILE_NAME,
  SEPOLIA_CHAIN_ID,
  SEPOLIA_CONTRACT_ADDRESS,
  SEPOLIA_ETHERSCAN_URL,
  SM_BREAKPOINT,
  TLDR_SECTIONS,
  WHITE,
};
