type LinkProps = {
  children: React.ReactNode;
  href: string;
  noUnderline?: boolean;
  style?: Record<string, string>;
};

const Link = ({ children, href, noUnderline = false, style }: LinkProps) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ ...(noUnderline && { textDecoration: "none" }), ...style }}
    >
      {children}
    </a>
  );
};

export default Link;
