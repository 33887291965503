import { useState } from "react";
import styled from "styled-components";
import {
  ADDRESSES,
  AMAZON_BOOK_URL,
  BLACK,
  BLUE,
  CONTRACT_ADDRESS,
  FADED_BLACK,
  GRAY,
  MD_BREAKPOINT,
  WHITE,
} from "./constants";
import Link from "./link";
import { DD, DT, H1, Mono, PseudoLink, SuccessIcon } from "./styles";
import Tldr from "./tldr";
import { AboutSection } from "./types";

type AboutModalProps = {
  onClose: () => void;
  section: AboutSection | null;
  setSection: (newSection: AboutSection | null) => void;
};

const handleModalClick = (event: any) => event.stopPropagation();

const Background = styled.div`
  align-items: center;
  background-color: ${FADED_BLACK};
  display: flex;
  height: 100vh;
  justify-content: space-around;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
`;

const Body = styled.div`
  max-height: calc(100% - 148px);
  overflow: scroll;
  padding-right: 12px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${WHITE};
  cursor: pointer;
  font-size: 14pt;
  width: auto;

  &:hover {
    color: ${BLUE};
  }
`;

const Header = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Modal = styled.div`
  background-color: ${BLACK};
  border: 2px solid ${WHITE};
  border-radius: 2px;
  height: 100%;
  max-height: min(800px, calc(100vh - 28px));
  max-width: min(600px, calc(100vw - 42px));
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 12px 18px;
  width: 100%;
`;

const QA = styled.div`
  margin-bottom: 40px;

  p:nth-of-type(1) {
    color: ${GRAY};
    font-family: monospace;
    font-size: 14px;
  }

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    p:nth-of-type(1) {
      font-size: 16px;
    }
  }
`;

const SectionHeader = styled.h2<{ selected: boolean }>`
  border-bottom: 2px solid
    ${({ selected }) => (selected ? WHITE : "transparent")};
  cursor: pointer;

  font-size: 20px;
  margin-bottom: 18px;
  margin-top: 18px;

  &:hover {
    color: ${BLUE};
    border-bottom: 2px solid
      ${({ selected }) => (selected ? BLUE : "transparent")};
  }

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    font-size: 26px;
  }
`;

const SubHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
  justify-content: center;
`;

const AboutModal = ({ onClose, section, setSection }: AboutModalProps) => {
  const [copiedText, setCopiedText] = useState<string | null>(null);

  const handleAuthorClick = () => setSection(AboutSection.Author);
  const handleBookClick = () => setSection(AboutSection.Book);
  const handleMintClick = () => setSection(AboutSection.Mint);

  const handleCopy = async (text: string) => {
    if (!navigator?.clipboard) {
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);

      setTimeout(() => {
        setCopiedText(null);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Background onClick={onClose}>
      <Modal onClick={handleModalClick}>
        <Header>
          <div />
          <H1 style={{ marginTop: "12px" }}>About</H1>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <SubHeader>
          <SectionHeader
            onClick={handleBookClick}
            selected={section === AboutSection.Book}
          >
            Book
          </SectionHeader>
          <SectionHeader
            onClick={handleMintClick}
            selected={section === AboutSection.Mint}
          >
            Mint
          </SectionHeader>
          <SectionHeader
            onClick={handleAuthorClick}
            selected={section === AboutSection.Author}
          >
            Author
          </SectionHeader>
        </SubHeader>
        {section === AboutSection.Book && (
          <Body>
            <Mono as="p" style={{ textAlign: "center", color: GRAY }}>
              Hello. You've found the site for <em>The Right to Transact</em>,
              by{" "}
              <PseudoLink onClick={() => setSection(AboutSection.Author)}>
                Zelinar XY
              </PseudoLink>
              . You can buy and read a digital version{" "}
              <PseudoLink onClick={() => setSection(AboutSection.Mint)}>
                here
              </PseudoLink>
              , or get a hard copy at <Link href={AMAZON_BOOK_URL}>Amazon</Link>
              . Here's the tl;dr:
            </Mono>
            <Tldr />
          </Body>
        )}
        {section === AboutSection.Mint && (
          <Body>
            <QA>
              <p>"What is this? Where am I?"</p>
              <p>
                You're in the internet, and this is{" "}
                <em>The Right to Transact</em>, a book by me, Zelinar XY, in NFT
                form. The text is stored on-chain. Kinda nifty. If you mint one
                of these NFTs, you can read the text or download it as a PDF
                right here on this very website.
              </p>
            </QA>
            <QA>
              <p>"What's the book about?"</p>
              <p>
                <PseudoLink onClick={() => setSection(AboutSection.Book)}>
                  Lots
                </PseudoLink>
                .
              </p>
            </QA>
            <QA>
              <p>"Who are you?"</p>
              <p>
                <PseudoLink onClick={() => setSection(AboutSection.Author)}>
                  Milady
                </PseudoLink>
                .
              </p>
            </QA>
            <QA>
              <p>"Where do I mint?"</p>
              <p>
                You're already here. Just{" "}
                <PseudoLink onClick={onClose}>close</PseudoLink> this dialog.
              </p>
            </QA>
            <QA>
              <p>"How does minting a book as an NFT work exactly?"</p>
              <p>
                The HTML version of the book has been compressed using
                Vectorized's{" "}
                <Link href="https://github.com/Vectorized/solady/blob/main/src/utils/LibZip.sol">
                  LibZip
                </Link>{" "}
                library and written to Arbitrum. The data is decompressed
                on-chain (again using LibZip) and returned by <Mono>read</Mono>{" "}
                (the function takes an <Mono>index</Mono> argument, since the
                text is split into multiple parts). When you visit the site, you
                see this raw HTML straight from the chain, plus a bit of CSS on
                the client side to make it pleasant. There's also a PDF you can
                download, which isn't on-chain or anything, but is just nice to
                have.
              </p>
              <p>
                Even if the site goes down, you can read the book on{" "}
                <Link
                  href={`https://arbiscan.io/address/${CONTRACT_ADDRESS}#readContract`}
                >
                  Arbiscan
                </Link>{" "}
                (connect your wallet and pass indices to the <Mono>read</Mono>{" "}
                function starting at <Mono>0</Mono> and stopping at{" "}
                <Mono>31</Mono>).
              </p>
            </QA>
            <QA>
              <p>"Roadmap?"</p>
              <p>No.</p>
              <p>
                If you're minting, you're basically making a donation. You'll
                have access to the digital version of the book, of course, but
                buying a copy on <Link href={AMAZON_BOOK_URL}>Amazon</Link> is
                cheaper. Should you choose to mint, your generosity will help me
                avoid pecuniary ruin and motivate me to write more in the
                future. It will confer karmic bounty on yourself and your
                descendants. It will help me host this site.
              </p>
              <p>
                More importantly, it will help{" "}
                <Link href="https://github.com/Zodomo">Zodomo</Link>, a based
                Milady dev who helped me a lot with the trickiest and most
                important parts of the smart contract. It took him about eight
                seconds and he didn't ask for anything in return. A fifth of
                proceeds go to him.
              </p>
            </QA>
            <QA>
              <p>"Wen moon ser?"</p>
              <p>Literally never.</p>
              <p>
                Do not trade this. Just to make sure a secondary market would be
                pointless, I didn't add a supply cap. I suppose, since{" "}
                <Mono>totalSupply</Mono> has a <Mono>uint236</Mono> type,
                something weird happens after the
                115,​792,​089,​237,​316,​195,​423,​570,​985,​008,​687,​907,​853,​269,​984,​665,​640,​564,​039,​457,​584,​007,​913,​129,​639,​935th
                mint. I'd love to find out what.
              </p>
            </QA>
            <QA>
              <p>"I have bought your book already and I can prove it"</p>
              <p>
                Aww thanks you care me! Email me or twit me or fartcast me and
                I'll admin-mint you one. (
                <PseudoLink onClick={() => setSection(AboutSection.Author)}>
                  Contact info
                </PseudoLink>
                .)
              </p>
            </QA>
            <QA>
              <p>"Do you care me?"</p>
              <p>I care you.</p>
            </QA>
          </Body>
        )}
        {section === AboutSection.Author && (
          <Body>
            <p>
              Hello. My name is Zelinar XY, or ZXY for short. I'm a writer,
              software developer and greengrocer. I wrote{" "}
              <em>
                <PseudoLink onClick={() => setSection(AboutSection.Book)}>
                  The Right to Transact
                </PseudoLink>
              </em>
              . You can reach me at <Mono>zelinarxy [at] proton [dot] me</Mono>
            </p>
            <p>
              {" "}
              Follow me on{" "}
              <Link href="https://warpcast.com/zelinarxy">Farcaster</Link>. Or{" "}
              <Link href="https://twitter.com/focaballena">Tweeter.</Link> Or{" "}
              <Link href="https://zelinarxy.substack.com/">Substack</Link>. Here
              is my actual face:
            </p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <img
                alt="avatar"
                src="avatar.jpg"
                style={{
                  height: "130px",
                  width: "130px",
                  borderRadius: "100%",
                }}
              />
            </div>
            <p>
              You may send me money for no reason here but I'd rather you just{" "}
              <PseudoLink onClick={() => setSection(AboutSection.Mint)}>
                mint
              </PseudoLink>
              :
            </p>
            <dl>
              {ADDRESSES.map(([network, address]) => (
                <div key={`${network}-${address}`}>
                  <DT>{network}</DT>
                  <DD onClick={() => handleCopy(address)}>
                    {address}
                    <SuccessIcon>
                      {copiedText === address ? "✔" : null}
                    </SuccessIcon>
                  </DD>
                </div>
              ))}
            </dl>
          </Body>
        )}
      </Modal>
    </Background>
  );
};

export default AboutModal;
