enum AboutSection {
  Author = "Author",
  Book = "Book",
  Mint = "Mint",
}

enum Environment {
  Dev = "dev",
  Prod = "prod",
}

export { AboutSection, Environment };
