import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  rabbyWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  WagmiProvider,
  createConfig,
  deserialize,
  http,
  serialize,
} from "wagmi";
import { arbitrum, sepolia } from "wagmi/chains";
import App from "./main";
import "./index.css";
import "./polyfills";
import "@rainbow-me/rainbowkit/styles.css";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Based",
      wallets: [metaMaskWallet, rabbyWallet, walletConnectWallet],
    },
  ],
  {
    appName: "Right to Transact",
    projectId: "12a05f33fb6115a131c9fef7ead00bf0",
  },
);

const config = createConfig({
  connectors,
  chains: [arbitrum, sepolia],
  transports: {
    [arbitrum.id]: http(),
    [sepolia.id]: http(),
  },
});

const persister = createSyncStoragePersister({
  serialize,
  storage: window.localStorage,
  deserialize,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        {" "}
        <RainbowKitProvider theme={darkTheme()} initialChain={arbitrum}>
          <App />
        </RainbowKitProvider>
      </PersistQueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);
