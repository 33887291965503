import styled from "styled-components";
import { BLACK, BLUE, FADED_BLACK, MD_BREAKPOINT, WHITE } from "./constants";
import { Button, Errors, Flex, H1, Info, StyledError } from "./styles";

type InfoModalProps = {
  buttonText?: string;
  errors: Array<string>;
  image?: {
    alt: string;
    height: string;
    src: string;
    width: string;
  };
  info: string | null;
  onClose: () => void;
};

const handleModalClick = (event: any) => event.stopPropagation();

const Background = styled.div`
  align-items: center;
  background-color: ${FADED_BLACK};
  display: flex;
  height: 100vh;
  justify-content: space-around;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
`;

const Body = styled.div`
  max-height: calc(100% - 148px);
  overflow: scroll;
  padding-right: 12px;
  text-align: center;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${WHITE};
  cursor: pointer;
  font-size: 14pt;
  width: auto;

  &:hover {
    color: ${BLUE};
  }
`;

const Header = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
`;

const Modal = styled.div`
  background-color: ${BLACK};
  border: 2px solid ${WHITE};
  border-radius: 2px;
  height: 100%;
  max-height: calc(100vh - 28px);
  max-width: calc(100vw - 42px);
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 12px 18px;
  width: 100%;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    max-height: 500px;
    max-width: 600px;
  }
`;

const InfoModal = ({
  buttonText,
  errors,
  image,
  info,
  onClose,
}: InfoModalProps) => {
  if (!errors?.length && !info) return null;

  return (
    <Background onClick={onClose}>
      <Modal onClick={handleModalClick}>
        <Header>
          <div />
          <H1 style={{ marginTop: "12px" }}>{errors?.length ? "Error" : ""}</H1>
          {onClose ? <CloseButton onClick={onClose}>×</CloseButton> : <div />}
        </Header>
        <Body>
          <div style={{ marginBottom: "32px" }}>
            {errors?.length ? (
              <Errors>
                {errors.map((error, index) => (
                  <StyledError key={`${error.slice(0, 10)}-${index}`}>
                    {error}
                  </StyledError>
                ))}
              </Errors>
            ) : (
              <Info>{info}</Info>
            )}
            {image && (
              <img
                src={image.src}
                alt={image.alt}
                width={image.width}
                height={image.height}
                style={{ marginTop: "16px" }}
              />
            )}
          </div>
          <Flex justifyContent="space-around" width="100%">
            <Button disabled={!onClose} onClick={onClose} width="180px">
              {buttonText ?? "Okay"}
            </Button>
          </Flex>
        </Body>
      </Modal>
    </Background>
  );
};

export default InfoModal;
