import { useCallback, useState } from "react";
import styled from "styled-components";
import { BLUE, TLDR_SECTIONS } from "./constants";
import { Button } from "./styles";

const BottomDivider = styled.div`
  border-bottom: 2px solid ${BLUE};
  height: 28px;
  width: 40%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
`;

const DividerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const Text = styled.p`
  font-family: monospace;
  margin-bottom: 28px;
  margin-top: 0;
  text-align: center;
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

const TopDivider = styled.div`
  border-bottom: 2px solid ${BLUE};
  height: 28px;
  margin-bottom: 28px;
  width: 40%;
`;

const Tldr = () => {
  const [tldrIndex, setTldrIndex] = useState<number>(0);

  const handleTldrBackClick = useCallback(() => {
    if (tldrIndex > 0) setTldrIndex(tldrIndex - 1);
  }, [setTldrIndex, tldrIndex]);

  const handleTldrNextClick = useCallback(() => {
    if (tldrIndex < TLDR_SECTIONS.length - 1) setTldrIndex(tldrIndex + 1);
  }, [setTldrIndex, tldrIndex]);

  return (
    <Container>
      <DividerContainer>
        <TopDivider />
      </DividerContainer>
      <TextContainer>
        <Text>{TLDR_SECTIONS[tldrIndex]}</Text>
      </TextContainer>
      <ButtonsContainer>
        <Button disabled={tldrIndex < 1} onClick={handleTldrBackClick}>
          Back
        </Button>
        <Button
          disabled={tldrIndex > TLDR_SECTIONS.length - 2}
          onClick={handleTldrNextClick}
        >
          Next
        </Button>
      </ButtonsContainer>
      <DividerContainer>
        <BottomDivider />
      </DividerContainer>
    </Container>
  );
};

export default Tldr;
