import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ReactNode } from "react";
import { useAccount } from "wagmi";
import AboutModal from "./aboutModal";
import InfoModal from "./infoModal";
import Link from "./link";
import {
  Avatar,
  Body,
  Button,
  Container,
  Flex,
  Header,
  HideDesktop,
  HR,
  H1,
  OuterContainer,
} from "./styles";
import { AboutSection } from "./types";

type LayoutProps = {
  aboutSection: AboutSection | null;
  blockExplorerUrl: string;
  children: ReactNode;
  errors: Array<string>;
  handleToggleMobileControls: (shouldHide: boolean) => void;
  shouldHideMobileControls: boolean;
  info: string | null;
  mintSuccess: boolean;
  setAboutSection: (newAboutSection: AboutSection | null) => void;
  setErrors: (newErrors: Array<string>) => void;
  setInfo: (newInfo: string | null) => void;
  setMintSuccess: (newMintSuccess: boolean) => void;
};

const Layout = ({
  aboutSection,
  blockExplorerUrl,
  children,
  errors,
  handleToggleMobileControls,
  info,
  mintSuccess,
  setAboutSection,
  setErrors,
  setInfo,
  setMintSuccess,
  shouldHideMobileControls,
}: LayoutProps) => {
  const { address } = useAccount();

  return (
    <>
      <OuterContainer>
        <Container>
          <Header>
            <Flex gap="14px">
              <Avatar
                alt="milady"
                onClick={() => setAboutSection(AboutSection.Author)}
                src="/avatar.jpg"
              />
              <H1 onClick={() => setAboutSection(AboutSection.Book)}>
                The Right to Transact
              </H1>
            </Flex>
            <Flex gap="8px">
              {shouldHideMobileControls && (
                <HideDesktop>
                  <Button onClick={() => handleToggleMobileControls(false)}>
                    ⛭
                  </Button>
                </HideDesktop>
              )}
              {!address && (
                <ConnectButton.Custom>
                  {({ openConnectModal }) => (
                    <Button onClick={openConnectModal} width="180px">
                      Connect
                    </Button>
                  )}
                </ConnectButton.Custom>
              )}
              {address && (
                <Link
                  href={`${blockExplorerUrl}/address/${address}`}
                  noUnderline
                  style={{ width: "150px" }}
                >
                  <Button>
                    {address.slice(0, 5)}...{address.slice(38)}
                  </Button>
                </Link>
              )}
            </Flex>
          </Header>

          <HR />

          <Body>{children}</Body>
        </Container>
      </OuterContainer>

      {!!aboutSection && (
        <AboutModal
          onClose={() => setAboutSection(null)}
          section={aboutSection}
          setSection={setAboutSection}
        />
      )}

      <HideDesktop>
        {(errors.length || info) && (
          <InfoModal
            errors={errors}
            info={info}
            onClose={() => {
              setInfo(null);
              setErrors([]);
            }}
          />
        )}
      </HideDesktop>

      {mintSuccess && (
        <InfoModal
          buttonText="Yay"
          errors={[]} // make sure we don't show any errors here
          image={{
            alt: "i can't believe we logged on and won forever meme",
            height: "240px",
            width: "240px",
            src: "/logged-on-won-forever.png",
          }}
          info="You did it! You logged on and won forever!"
          onClose={() => {
            setMintSuccess(false);
          }}
        />
      )}
    </>
  );
};

export default Layout;
