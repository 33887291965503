import styled from "styled-components";
import { BLACK, BLUE, GRAY, MD_BREAKPOINT, SM_BREAKPOINT, WHITE } from "./constants";

const Avatar = styled.img`
  border-radius: 1px;
  cursor: pointer;
  display: none;
  width: 48px;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    display: flex;
  }
`;

const Body = styled.div`
  display: grid;
  column-gap: 28px;
  row-gap: 12px;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    grid-template-columns: auto 580px;
  }
`;

const Book = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["controlsHiddenMobile", "isBookmarking", "isWide"].includes(prop),
})<{ controlsHiddenMobile: boolean; isBookmarking: boolean; isWide: boolean }>`
  border: solid 2px ${WHITE};
  border-radius: 2px;
  height: calc(
    100vh -
      ${({ controlsHiddenMobile }) =>
        controlsHiddenMobile ? "170px" : "220px"}
  );
  overflow-y: scroll;
  padding: 12px;

  pre {
    font-size: 9.5px;
  }

  ${({ isBookmarking }) =>
    isBookmarking &&
    `
    & p, & h1, & h2, & h3, & h4 {
      cursor: pointer;
    }

    & div {
      cursor: not-allowed;
    }
  `}

  ${({ isWide }) => isWide && `grid-column: 1/-1;`}

  @media only screen and (min-width: 500px) {
    height: calc(
      100vh -
        ${({ controlsHiddenMobile }) =>
          controlsHiddenMobile ? "140px" : "200px"}
    );
  }

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    height: calc(100vh - 175px ${({ isWide }) => isWide && "- 76px"});
  }

  @media only screen and (max-width: ${SM_BREAKPOINT}) {
    pre {
      display: none;
    }
  }
`;

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isActive", "width"].includes(prop),
})<{ disabled?: boolean; isActive?: boolean; width?: string }>`
  align-items: center;
  background-color: ${BLACK};
  border-radius: 2px;
  border: solid 2px ${({ disabled }) => (disabled ? GRAY : WHITE)};
  color: ${({ disabled }) => (disabled ? GRAY : WHITE)};
  ${({ disabled }) => !disabled && "cursor: pointer;"}
  display: flex;
  font-family: monospace;
  font-size: 11px;
  justify-content: space-around;
  padding: 4px;
  width: ${({ width }) => width || "100%"};

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
    border: solid 2px ${BLUE};
    color: ${BLUE};
  }`}

  ${({ isActive }) =>
    isActive &&
    `
    border: solid 2px ${BLUE};
    color: ${BLUE};
  `}

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    font-size: 13px;
    min-height: 36px;

    ${({ disabled }) =>
      !disabled &&
      `&:hover {
    font-size: 13.4px;
  }`}
  }
`;

const Container = styled.div`
  max-width: 820px;
  text-align: justify;
  width: 820px;
  word-wrap: break-word;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    padding: 18px;
  }
`;

const ControlButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DD = styled.dd`
  cursor: pointer;
  font-family: monospace;
  font-size: 16px;
  margin-bottom: 16px;
  margin-left: 0;
`;

const DT = styled.dt`
  font-family: monospace;
  font-size: 16px;
  margin-bottom: 8px;
`;

const DesktopControls = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    display: flex;
  }
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: scroll;
`;

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "alignItems",
      "flexDirection",
      "gap",
      "gridColumn",
      "justifyContent",
      "marginTop",
      "paddingTop",
      "width",
    ].includes(prop),
})<{
  alignItems?: string;
  flexDirection?: string;
  gap?: string;
  gridColumn?: string;
  justifyContent?: string;
  marginTop?: string;
  paddingTop?: string;
  width?: string;
}>`
  align-items: ${({ alignItems }) => alignItems || "center"};
  display: flex;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ gridColumn }) => gridColumn && `grid-column: ${gridColumn};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
  ${({ width }) => width && `width: ${width};`}
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    height: 68px;
  }
`;

const HideDesktop = styled.div`
  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    display: none;
  }
`;

const HR = styled.hr`
  color: ${WHITE};
  margin-bottom: 16px;
`;

const H1 = styled.h1`
  cursor: pointer;
  font-size: 24px;
  margin: 0;
  text-align: left;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    font-size: 36px;
  }
`;

const Info = styled.div`
  color: ${BLUE};
  font-family: monospace;
  font-size: 16px;
`;

const MintContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MintOuterContainer = styled.div`
  display: flex;
  grid-column: 1/-1;
  justify-content: space-around;
  margin-top: 36px;
  width: 100%;
`;

const MobileControls = styled.div`
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    display: none;
  }
`;

const Mono = styled.span`
  font-family: monospace;
  font-size: 14px;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    font-size: 16px;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const PseudoLink = styled.span`
  color: skyblue;
  cursor: pointer;
  text-decoration: underline;
`;

const ShowControlsContainer = styled.div`
  align-items: center;
  display: none;
  grid-column: 1/-1;
  justify-content: start;
  width: 100%;

  @media only screen and (min-width: ${MD_BREAKPOINT}) {
    display: flex;
  }
`;

const StyledError = styled.div`
  color: red;
  word-break: break-all;
`;

const SuccessIcon = styled.span`
  color: skyblue;
  margin-left: 16px;
`;

export {
  Avatar,
  Body,
  Book,
  Button,
  Container,
  ControlButtons,
  DD,
  DesktopControls,
  DT,
  Errors,
  Flex,
  Header,
  HideDesktop,
  HR,
  H1,
  Info,
  MintContainer,
  MintOuterContainer,
  MobileControls,
  Mono,
  OuterContainer,
  PseudoLink,
  ShowControlsContainer,
  StyledError,
  SuccessIcon,
};
